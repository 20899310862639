import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = () => 'warehouse/sale-rental'
const Actions = crudActions(endpoint)
// eslint-disable-next-line no-unused-vars
// const getRentalSalesOrders = ({ commit }, id) => axios.get(`${endpoint()}/${id}/pick`)
const getRentalSalesOrders = (ctx, queryParams) => axios.get(`${endpoint()}/fulfillment`, { params: queryParams })

export default {
  ...Actions,
  getRentalSalesOrders,
}
