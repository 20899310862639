<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <span class="font-weight-bolder font-medium-5 d-block pt-2">
        {{ $t('Dispatch') }}
      </span>
      <b-row class="mt-1 pb-1">
        <b-col md="9">
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <span class="w-50 font-weight-bolder">
              {{ $t('Date') }}
            </span>
            <span class="w-50 font-weight-bolder">
              {{ convertTZ(getValueFromGivenObjectByKey(rentalOrder, 'dispatch_details.date', '&mdash;')) }}
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mt-1"
          >
            <span class="w-50 font-weight-bolder">
              {{ $t('Method') }}
            </span>
            <span class="w-50">
              {{ getValueFromGivenObjectByKey(rentalOrder, 'dispatch_details.method', '&mdash;') }}
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mt-1"
          >
            <span class="w-50 font-weight-bolder">
              {{ $t('Dispatch Location') }}
            </span>
            <span class="w-50 font-weight-bolder">
              {{ getValueFromGivenObjectByKey(rentalOrder, 'dispatch_details.location', '&mdash;') }}
            </span>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'

export default {
  name: 'RentalSalesDispatch',
  components: { BCol, BRow },
  computed: {
    rentalOrder() {
      return this.$store.state[this.MODULE_NAME].rentalSaleOrder
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      getValueFromGivenObjectByKey,
      MODULE_NAME,
      tableColumns,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
